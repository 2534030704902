import { isEmpty } from '@vivino/js-web-common';
import { BasicVintageWithBasicWine } from '@webtypes/goApi';
import { RubyLibVintagePrice } from '@webtypes/rubyLibApi';
import React from 'react';

import WineBand from 'vivino-js/components/WineBand';

import styles from './wineBandWithSimpleHeader.scss';

interface WineBandWithSimpleHeaderProps {
  header: string;
  subHeader: string;
  showAllUrl: string;
  showOutOfStock?: boolean;
  isMasterWineCard?: boolean;
  showWithoutPrices?: boolean;
  useLabelShots?: boolean;
  vintages: BasicVintageWithBasicWine[];
  prices: { [id: number]: RubyLibVintagePrice };
}

export const WineBandWithSimpleHeader = ({
  vintages,
  prices,
  header,
  subHeader,
  showOutOfStock = true,
  ...rest
}: WineBandWithSimpleHeaderProps) => {
  if (isEmpty(vintages)) {
    return null;
  }

  return (
    <div className={styles.wineBand}>
      <div className={styles.wineBandHeader}>
        <div className={styles.wineBandHeadline}>{header}</div>
        <div className={styles.wineBandSubheadline}>{subHeader}</div>
      </div>
      <div>
        <WineBand vintages={vintages} prices={prices} showOutOfStock={showOutOfStock} {...rest} />
      </div>
    </div>
  );
};

export default WineBandWithSimpleHeader;
